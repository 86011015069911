import { AlertColor } from "@mui/material";
import {
  AUCTION_REQUEST_ACCEPTED,
  AUCTION_REQUEST_CREATED,
  AUCTION_REQUEST_DECLINED,
  AUCTION_REQUEST_REJECTED,
  AUCTION_REQUEST_SENT,
  AUCTION_REQUEST_UNAVAILABLE,
  CAREPROVIDER_CREATED,
  CAREPROVIDER_INACTIVE,
  DISTANCE_FILTER_RECEIVER,
  FILTER_TYPES,
  RECEIVER_INACTIVE_REASONS,
} from "core/consts";
import { declineDetailsReason } from "core/model/auctions";
import { formatUnixDate } from "core/model/utils/dates";
import { grammaticalList } from "core/model/utils/strings";
import {
  AuctionRequest,
  Careprovider,
  EventFilter,
  GetOntologyType,
} from "core/types";
import { BannerProps } from "ds/ui";
import { getNoCapacityReasonsMessage } from "dsl/ecosystems/CareproviderSettingsCapacity/utils";
import { Locale as LocaleString } from "translations";
import Translations from "translations/types";

export type StopSearchWarning = {
  message: string;
  severity: AlertColor;
};

export const requestAlreadyAccepted = (translations: Translations) =>
  `${translations.patient.addCareprovider.errorRequestAccepted}\n${translations.patient.addCareprovider.pleaseGoToAnswer}`;

function getExistingRequestWarningMessage(
  auctionRequest: AuctionRequest | null | undefined,
  translations: Translations,
  locale: Locale,
  getOntology: GetOntologyType,
): StopSearchWarning | null {
  if (!auctionRequest) return null;

  let message: string | null = null;
  let severity: AlertColor | BannerProps["color"] = "warning";

  switch (auctionRequest.status) {
    case AUCTION_REQUEST_REJECTED:
      message = translations.patient.addCareprovider.errorRequestRejected;
      break;
    case AUCTION_REQUEST_ACCEPTED: {
      severity = "success";
      message = requestAlreadyAccepted(translations);
      break;
    }

    case AUCTION_REQUEST_SENT:
    case AUCTION_REQUEST_CREATED: {
      message =
        translations.patient.addCareprovider.errorRequestSentAutomaticSearch;
      break;
    }
    case AUCTION_REQUEST_DECLINED: {
      message = translations.patient.addCareprovider.errorRequestDeclined({
        message:
          declineDetailsReason({
            getOntology,
            options: auctionRequest.response,
            searchType: auctionRequest.careprovider?.patient_type,
            translations,
            withHyphen: true,
            locale,
          }) || "",
      });
      break;
    }
    case AUCTION_REQUEST_UNAVAILABLE:
      message = "";
      break;
    default:
      message = translations.patient.addCareprovider.errorContactRecare;
      break;
  }

  return message ? { message, severity } : null;
}

export const getWarningMessage = ({
  careprovider,
  faxActivated,
  forProviderRecommendations,
  getOntology,
  locale,
  localeString,
  reason,
  request,
  translations,
}: {
  careprovider: Careprovider;
  faxActivated?: boolean | number | null;
  forProviderRecommendations?: boolean;
  getOntology: GetOntologyType;
  locale: Locale;
  localeString: LocaleString;
  reason: EventFilter;
  request?: AuctionRequest;
  translations: Translations;
}): StopSearchWarning | null => {
  let message: string | null = null;
  let severity: AlertColor | BannerProps["color"] = "warning";

  switch (reason.type) {
    case FILTER_TYPES.FILTER_BLACKLISTED_INSURANCE:
      message = translations.patient.addCareprovider.insuranceBlacklisted;
      break;
    case FILTER_TYPES.FILTER_RECEIVER_BLACKLISTED:
      message = translations.careprovider.providerNotAvailable;
      break;
    case FILTER_TYPES.FILTER_SENDER_BLACKLISTED:
      message = translations.patient.addCareprovider.warningWhitelist;
      break;
    case FILTER_TYPES.FILTER_THROTTLE:
      message = translations.patient.addCareprovider.providerThrottled;
      break;
    case FILTER_TYPES.FILTER_STATUS: {
      const inactiveReason = careprovider?.inactive_reason;
      const showInactiveReasonForChains =
        inactiveReason === RECEIVER_INACTIVE_REASONS.UNNNEEDED_CHAIN_CENTER ||
        inactiveReason === RECEIVER_INACTIVE_REASONS.UNNNEEDED_CHAIN_SUBSIDIARY;

      const showInactiveReasonForClosed =
        inactiveReason === RECEIVER_INACTIVE_REASONS.CLOSED ||
        inactiveReason === RECEIVER_INACTIVE_REASONS.TEMPORARY_CLOSED ||
        inactiveReason === RECEIVER_INACTIVE_REASONS.NO_ACCOUNT;

      if (careprovider.status === CAREPROVIDER_CREATED && !faxActivated) {
        message = translations.patient.addCareprovider.errorProviderCreated;
        break;
      }

      if (careprovider.status === CAREPROVIDER_INACTIVE) {
        if (showInactiveReasonForChains) {
          message = getOntology({
            type: "receiverInactiveReason",
            key: careprovider?.inactive_reason,
          });
          break;
        }
        if (showInactiveReasonForClosed) {
          message =
            translations.patient.addCareprovider.errorProviderCannotBeContacted;
          break;
        }
      }

      message =
        faxActivated && !forProviderRecommendations
          ? translations.patient.addCareprovider.contactingByFax
          : translations.patient.addCareprovider.errorProviderNotActive;
      break;
    }

    case FILTER_TYPES.FILTER_NO_BED_AVAILABLE:
      message = translations.patient.addCareprovider.noBedsAvailable;
      break;
    case FILTER_TYPES.FILTER_CAPACITY_START_DATE:
      message = careprovider?.capacity?.available_date
        ? translations.patient.addCareprovider.warningNoCapacity({
            startDate: formatUnixDate(
              careprovider.capacity.available_date,
              localeString,
            ),
          })
        : "";
      break;
    case FILTER_TYPES.FILTER_ZIPCODES:
      message = translations.patient.addCareprovider.warningNotCatchment;
      break;
    case FILTER_TYPES.FILTER_SERVICE:
      message = translations.patient.addCareprovider.warningNoService({
        service: (reason.values || [reason.value || -1])
          .map((key) => getOntology({ type: "service", key }))
          .join(", "),
      });
      break;
    case FILTER_TYPES.FILTER_DISTANCE:
      if (reason?.value === DISTANCE_FILTER_RECEIVER) {
        message =
          translations.patient.addCareprovider
            .warningDistanceCareproviderRadius;

        break;
      }
      message = translations.patient.addCareprovider.warningDistance;
      break;
    case FILTER_TYPES.FILTER_PREREQUISITE:
      message = translations.patient.addCareprovider.warningPrerequisite({
        service: (reason.values || [reason.value || -1])
          .map((key) => getOntology({ type: "service", key }))
          .join(", "),
      });
      break;
    case FILTER_TYPES.FILTER_SOLUTION:
      message = translations.patient.addCareprovider.errorNoSolution({
        solution: getOntology({ type: "solution", key: reason.value }),
      });
      break;
    case FILTER_TYPES.FILTER_CONTACTED: {
      const warning = getExistingRequestWarningMessage(
        request,
        translations,
        locale,
        getOntology,
      );
      if (warning) {
        message = warning.message;
        severity = warning.severity;
      }
      break;
    }
    case FILTER_TYPES.FILTER_CAPACITY_BACKOFF:
      if (!reason.no_capacity_expiration) {
        message = null;
        break;
      }

      message = `${translations.patient.addCareprovider.warningNoCapacity({
        startDate: formatUnixDate(reason.no_capacity_expiration, localeString),
      })}: ${getNoCapacityReasonsMessage({
        translations,
        getOntology,
        reasons: reason.no_capacity_reasons,
      })}`;
      break;
    case FILTER_TYPES.FILTER_SPECIALIZATION:
      message = translations.patient.addCareprovider.errorNoSolution({
        solution:
          grammaticalList({
            array: reason.values,
            ontologyType: "specializations",
            getOntology,
            translations,
          }) || "",
      });
      break;
    case FILTER_TYPES.FILTER_AGE:
      if (!careprovider.profile?.accepted_age) {
        message = null;
        break;
      }

      message = translations.patient.addCareprovider.warningAge({
        minAge: (careprovider.profile.accepted_age.min || 0).toString(),
        maxAge: (careprovider.profile?.accepted_age?.max || 120).toString(),
      });
      break;
    case FILTER_TYPES.FILTER_WEIGHT:
      if (!careprovider.profile?.accepted_weight?.max) {
        message = null;
        break;
      }

      message = translations.patient.addCareprovider.title.warningWeight({
        maxWeight: careprovider.profile?.accepted_weight?.max.toString(),
      });
      break;

    default: {
      console.error(
        `Could not handle filter type ${reason.type} in provider warnings`,
        {
          careprovider_id: careprovider?.id,
          reason_value: JSON.stringify({
            value: reason.value,
            values: reason.values,
          }),
        },
      );
    }
  }

  return message ? { message, severity } : null;
};
