import React, { useContext } from "react";
import {
  ACTION,
  PatientPreferredInput,
  RequestListReducerActionTypes,
  RequestListState,
  RequestListType,
} from "./PotentialReceiverTableReducer";

type PotentialReceiverContextType = {
  deselectAllRequests: () => void;
  getRequestState: (id: number) => RequestListType | undefined;
  requestListState: RequestListState;
  setAutomaticSearch: () => void;
  setPatientPreferred: (value: PatientPreferredInput) => void;
  setRequest: (id: number) => void;
};

export const PotentialReceiverContext =
  React.createContext<PotentialReceiverContextType>({
    deselectAllRequests: () => {},
    setRequest: () => {},
    requestListState: { automaticSearch: false, requestFormState: {} },
    getRequestState: () => undefined,
    setPatientPreferred: () => {},
    setAutomaticSearch: () => {},
  });

export function usePotentialReceiverContext(): PotentialReceiverContextType {
  return useContext(PotentialReceiverContext);
}

export function PotentialReceiverContextProvider({
  children,
  dispatch,
  requestListState,
}: {
  children: JSX.Element;
  dispatch: React.Dispatch<RequestListReducerActionTypes>;
  requestListState: RequestListState;
}) {
  return (
    <PotentialReceiverContext.Provider
      value={{
        requestListState,
        setAutomaticSearch: () => {
          dispatch({
            type: ACTION.SET_AUTOMATIC_SEARCH,
          });
        },
        setRequest: (id: number) => {
          dispatch({ type: ACTION.SET_REQUEST, payload: { id } });
        },
        setPatientPreferred: (payload: PatientPreferredInput) => {
          dispatch({ type: ACTION.SET_PATIENT_PREFERRED, payload });
        },
        getRequestState: (id: number): RequestListType => {
          const state = requestListState.requestFormState?.[id];
          if (!state) {
            return {
              id,
              manually_selected: false,
              patient_preferred_reason: undefined,
              patient_preferred: false,
              careprovider_id: -1,
            };
          }
          return state;
        },
        deselectAllRequests: () => {
          dispatch({ type: ACTION.DESELECT_ALL_REQUESTS });
        },
      }}
    >
      {children}
    </PotentialReceiverContext.Provider>
  );
}
