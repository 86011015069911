import SvgIcon from "@mui/material/SvgIcon";
import { dp } from "ds_legacy/materials/metrics";

export function labelWithOptionalRequiredIcon({
  label,
  required,
  useAsterisk = true,
}: {
  label: string;
  required?: boolean;
  useAsterisk?: boolean;
}) {
  return (
    <>
      {required ? (
        <>
          {label} {useAsterisk ? <RequiredAsterisk /> : <RequiredIcon />}
        </>
      ) : (
        label
      )}
    </>
  );
}

const RequiredAsterisk = () => (
  <span role="presentation" aria-hidden>
    *
  </span>
);

const RequiredIcon = () => (
  <SvgIcon
    viewBox="0 0 24 24"
    aria-hidden
    role="presentation"
    sx={{ width: dp(8) }}
  >
    <path
      fill="currentColor"
      d="M21 13h-6.6l4.7 4.7l-1.4 1.4l-4.7-4.7V21h-2v-6.7L6.3 19l-1.4-1.4L9.4 13H3v-2h6.6L4.9 6.3l1.4-1.4L11 9.6V3h2v6.4l4.6-4.6L19 6.3L14.3 11H21z"
    />
  </SvgIcon>
);
