import { TRACK_EVENTS } from "core/consts";
import { CareseekerConfig } from "core/types";
import { customAlphabet } from "nanoid";
import React, { useEffect, useState } from "react";
import { useTracking } from "react-tracking";

const alphabet =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const nanoid = customAlphabet(alphabet, 8);
const TRACK_ID = nanoid();
const REFRESH_INTERVAL_MS = 2 * 60 * 1000;

export const HL7ImportStatusContext = React.createContext<{
  activate_gateway_debug_mode: boolean | undefined;
  activate_ssl_hl7_connector: boolean | undefined;
  gateway_version: string | undefined;
  hasOneWaySync: boolean | undefined;
  hasTwoWaySync: boolean | undefined;
  on_premise_authorization_token: string | undefined;
  on_premise_domain_ssl: string | undefined;
  sslConnected: boolean | undefined;
}>({
  activate_gateway_debug_mode: undefined,
  activate_ssl_hl7_connector: undefined,
  gateway_version: undefined,
  hasTwoWaySync: undefined,
  hasOneWaySync: undefined,
  on_premise_authorization_token: undefined,
  on_premise_domain_ssl: undefined,
  sslConnected: undefined,
});

export const useHL7ImportStatusContext = () =>
  React.useContext(HL7ImportStatusContext);

export function HL7ImportStatusContextProvider({
  children,
  config,
}: {
  children: React.ReactNode;
  config: CareseekerConfig | undefined;
}) {
  let interval: number | undefined;
  const {
    activate_gateway_debug_mode,
    activate_ssl_hl7_connector,
    on_premise_authorization_token,
    on_premise_domain_ssl,
    on_premise_file_sync,
  } = config ?? {};
  const [sslConnectionStatus, setSslConnected] = useState<
    { connected: boolean; version?: string } | undefined
  >();
  const { trackEvent } = useTracking();

  useEffect(() => {
    function testSSL() {
      fetch(`https://${on_premise_domain_ssl}/ping`)
        .then((response) => {
          setSslConnected({
            connected: response.status === 200,
            version: response.headers.get("hl7-connector-version") || undefined,
          });
          // track ok
          trackEvent({
            name: TRACK_EVENTS.SSL_IMPORT_PING_TEST,
            status: response.status,
            uid: TRACK_ID,
          });
        })
        .catch((err) => {
          setSslConnected({ connected: false });
          // track err
          trackEvent({
            name: TRACK_EVENTS.SSL_IMPORT_PING_TEST,
            status: -1,
            uid: TRACK_ID,
            error_details: typeof err === "string" ? err : JSON.stringify(err),
          });
        });
    }

    if (activate_ssl_hl7_connector) {
      testSSL();
      // refresh every 2 min
      interval = window.setInterval(() => {
        testSSL();
      }, REFRESH_INTERVAL_MS);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [activate_ssl_hl7_connector, on_premise_domain_ssl]);

  const importStatusValue = React.useMemo(() => {
    const hasOneWaySync =
      !!sslConnectionStatus?.connected && !activate_gateway_debug_mode;
    const hasTwoWaySync = hasOneWaySync && !!on_premise_file_sync;
    return {
      activate_gateway_debug_mode,
      activate_ssl_hl7_connector: activate_ssl_hl7_connector,
      gateway_version: sslConnectionStatus?.version,
      hasOneWaySync,
      hasTwoWaySync,
      on_premise_authorization_token,
      on_premise_domain_ssl,
      sslConnected: sslConnectionStatus?.connected,
    };
  }, [
    activate_gateway_debug_mode,
    activate_ssl_hl7_connector,
    on_premise_authorization_token,
    on_premise_domain_ssl,
    on_premise_file_sync,
    sslConnectionStatus,
  ]);

  return (
    <HL7ImportStatusContext.Provider value={importStatusValue}>
      {children}
    </HL7ImportStatusContext.Provider>
  );
}
