import {
  FormControl,
  FormHelperText,
  Paper,
  SxProps,
  Theme,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { PREDICAMENT_STATE_NO, PREDICAMENT_STATE_YES } from "core/consts";
import { useDateLocale } from "core/hooks/useDateLocale";
import { getCharacterCounter } from "core/model/utils/strings";
import { QueryProgressActive } from "core/types";
import { format, fromUnixTime } from "date-fns";
import CheckboxInputField from "ds_legacy/components/CheckboxInputField";
import DatePickerInputField, {
  ConnectedDateSelectorProps,
} from "ds_legacy/components/DatePickerInputField";
import { SubheadingFormLabel } from "ds_legacy/components/FormComponents/SubheadingFormLabel";
import RadioGroupV2, { RadioOptionV2 } from "ds_legacy/components/RadioGroupV2";
import RSButton from "ds_legacy/components/RSButton";
import ConnectedSelectInput, {
  ConnectedSelectInputProps,
} from "ds_legacy/components/SelectInput";
import {
  ConnectedTextarea,
  TextAreaProps,
} from "ds_legacy/components/TextArea";
import TextInputField, {
  ConnectedTextInputProps,
} from "ds_legacy/components/TextInputField";
import ToggleInputField from "ds_legacy/components/ToggleInputField";
import {
  CUSTOM_WHITE,
  GREY_600,
  PRIMARY_DARK_COLOR,
  RADIO_WHITE_BACKGROUND,
  WHITE,
} from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import {
  border,
  dp,
  margin,
  padding,
  sizing,
} from "ds_legacy/materials/metrics";
import { SHADOW_DROP_SMALL } from "ds_legacy/materials/shadows";
import {
  Body,
  Caption,
  FONT_SIZE_14,
  FONT_SIZE_16,
  FONT_SIZE_20,
  FONT_SIZE_24,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_REGULAR,
  Subheading,
  Title,
} from "ds_legacy/materials/typography";
import { useHL7ImportStatusContext } from "dsl/atoms/HL7ImportStatusContext";
import { FolderInputIcon, LockIcon, PrinterIcon } from "lucide-react";
import { CSSProperties, ReactNode } from "react";
import { FormElementProps, FormWatcher, isValid } from "react-forms-state";
import styled from "styled-components";
import { useTranslations } from "translations";
import Translations from "translations/types";

export const GAP = 3;

export const LARGE_INPUT_WIDTH = "35%";
export const LARGE_INPUT_MIN_WIDTH = dp(280);

export const SMALL_INPUT_WIDTH = `calc(17.5% - ${sizing(1.5)})`;
export const SMALL_INPUT_MIN_WIDTH = dp(130);

export const TEXT_AREA_MAX_CHARACTERS = 400;

export const StyledFormWrapper = styled(Paper)`
  box-shadow: ${SHADOW_DROP_SMALL} !important;
  border-radius: ${dp(6)};
  box-sizing: border-box;
  overflow: visible;
  width: 100%;
  max-width: ${dp(1366)};
  padding: ${padding(4, 15)};
  margin: ${sizing(5)} auto;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${sizing(1)};
  background-color: ${CUSTOM_WHITE};
  padding: ${padding(GAP)};
  box-sizing: border-box;
  overflow: visible;
`;

export const SectionRow = styled(HorizontalLayout)<{
  customPadding?: CSSProperties["padding"];
  fullWidth?: boolean;
}>`
  column-gap: ${sizing(GAP)};
  padding: ${({ customPadding }) => customPadding ?? padding(GAP, 0, 0)};
  box-sizing: border-box;
  flex-wrap: wrap;
  overflow: visible;
`;

export const Section = ({
  children,
  title,
}: {
  children: ReactNode[] | ReactNode;
  title?: string;
}) => {
  return (
    <>
      {title ? (
        <PatientFormsSectionHeader>{title}</PatientFormsSectionHeader>
      ) : null}
      <SectionWrapper>{children}</SectionWrapper>
    </>
  );
};

export const FormSectionsWrapper = ({
  children,
  noBottomMargin,
}: {
  children: ReactNode[] | ReactNode;
  noBottomMargin?: boolean;
}) => {
  return (
    <VerticalLayout
      width="100%"
      margin={margin(1, 0, noBottomMargin ? 1 : 3)}
      rowGap={sizing(GAP)}
    >
      {children}
    </VerticalLayout>
  );
};

export const WithLabel = ({
  bold,
  children,
  error,
  formLabelSx,
  label,
  required,
  wrapperSx,
}: {
  bold?: boolean;
  children: ReactNode;
  error: boolean;
  formLabelSx?: SxProps<Theme>;
  label: ReactNode | undefined;
  required?: boolean;
  wrapperSx: SxProps<Theme>;
}) => {
  return (
    <FormControl component="fieldset" error={error} sx={wrapperSx}>
      <SubheadingFormLabel
        sx={{
          margin: margin(0, 0, 1),
          fontSize: FONT_SIZE_14,
          fontWeight: bold ? FONT_WEIGHT_BOLD : undefined,
          ...formLabelSx,
        }}
      >
        <label>
          {label}
          {required ? " *" : ""}
        </label>
      </SubheadingFormLabel>
      {children}
    </FormControl>
  );
};

const LabelWrapper = ({
  bold,
  children,
  elementName,
  formLabelSx,
  label,
  required,
  wrapperSx,
}: {
  bold?: boolean;
  children: ReactNode;
  elementName: string;
  formLabelSx?: SxProps<Theme>;
  label: ReactNode | undefined;
  required?: boolean;
  wrapperSx: SxProps<Theme>;
}) => {
  return (
    <FormWatcher watchPath={elementName}>
      {({ watchedValidation }) => {
        return (
          <WithLabel
            bold={bold}
            label={label}
            error={!isValid(watchedValidation)}
            formLabelSx={formLabelSx}
            wrapperSx={wrapperSx}
            required={required}
          >
            {children}
          </WithLabel>
        );
      }}
    </FormWatcher>
  );
};

export const DatePickerWithLabel = (
  props: ConnectedDateSelectorProps & {
    bold?: boolean;
    elementName: string;
    wrapperSx?: SxProps;
  },
) => {
  const { bold, label, ...restProps } = props;
  return (
    <LabelWrapper
      bold={bold}
      wrapperSx={{
        width: SMALL_INPUT_WIDTH,
        minWidth: SMALL_INPUT_MIN_WIDTH,
        ...(restProps?.wrapperSx ?? {}),
      }}
      label={label}
      elementName={restProps.elementName}
      required={restProps.required}
    >
      <DatePickerInputField
        {...restProps}
        textInputVariant="outlined"
        inputSx={{
          "& .MuiInputBase-root": {
            background: WHITE,
          },
          width: "100%",
          ...(restProps?.inputSx ?? {}),
        }}
        formHelperTextProps={{ style: { margin: margin(1, 0, 0) } }}
      />
    </LabelWrapper>
  );
};

const CharacterLimit = ({
  characterLimit,
  characterLimitId,
  elementName,
}: {
  characterLimit?: number;
  characterLimitId: string;
  elementName: string;
}) => {
  const translations = useTranslations();

  if (!characterLimit) return null;

  return (
    <FormWatcher watchPath={elementName}>
      {({ watchedValidation, watchedValue }) => {
        if (watchedValidation !== true) return null;

        const characterLimitCounter = getCharacterCounter({
          characterLimit: characterLimit,
          value: watchedValue,
          translations,
        });

        return (
          <FormHelperText id={characterLimitId}>
            {characterLimitCounter}
          </FormHelperText>
        );
      }}
    </FormWatcher>
  );
};

export const TextInputWithLabel = ({
  bold,
  label,
  labelWrapperSx,
  inputProps = {},
  characterLimit,
  elementName,
  large,
  ...restProps
}: ConnectedTextInputProps & {
  bold?: boolean;
  characterLimit?: number;
  elementName: string;
  labelWrapperSx?: SxProps<Theme>;
  large?: boolean;
}) => {
  const characterLimitId = `${elementName}-character-limit`;
  return (
    <LabelWrapper
      bold={bold}
      wrapperSx={{
        width: large ? LARGE_INPUT_WIDTH : SMALL_INPUT_WIDTH,
        minWidth: large ? LARGE_INPUT_MIN_WIDTH : SMALL_INPUT_MIN_WIDTH,
        ...labelWrapperSx,
      }}
      label={label}
      elementName={elementName}
    >
      <TextInputField
        label={null}
        elementName={elementName}
        width="100%"
        marginOverride="0"
        variant="outlined"
        ariaDescribedBy={characterLimit ? characterLimitId : undefined}
        style={{
          background: WHITE,
        }}
        inputProps={{
          ...inputProps,
          maxLength: characterLimit,
        }}
        {...restProps}
      />
      <CharacterLimit
        characterLimit={characterLimit}
        characterLimitId={characterLimitId}
        elementName={elementName}
      />
    </LabelWrapper>
  );
};

export const TextAreaWithLabel = (
  props: TextAreaProps &
    FormElementProps & {
      bold?: boolean;
      elementName: string;
      formLabelSx?: SxProps<Theme>;
      wrapperSx?: SxProps<Theme>;
    },
) => {
  const { bold, formLabelSx, label, wrapperSx, ...restProps } = props;
  return (
    <LabelWrapper
      bold={bold}
      elementName={restProps.elementName}
      formLabelSx={formLabelSx}
      label={label}
      wrapperSx={{
        width: "100%",
        ...wrapperSx,
      }}
      required={props.required}
    >
      <ConnectedTextarea label={null} {...restProps} />
    </LabelWrapper>
  );
};

export const SelectWithLabel = (
  props: ConnectedSelectInputProps & {
    bold?: boolean;
    elementName: string;
    inputSx?: SxProps<Theme>;
    large?: boolean;
    wrapperSx: SxProps<Theme>;
  },
) => {
  const { bold, inputSx, label, wrapperSx, ...restProps } = props;
  return (
    <LabelWrapper
      bold={bold}
      elementName={restProps.elementName}
      label={label}
      wrapperSx={wrapperSx}
    >
      <ConnectedSelectInput
        label={null}
        {...restProps}
        size="small"
        variant="outlined"
        inputSx={{
          width: props.large ? LARGE_INPUT_WIDTH : SMALL_INPUT_WIDTH,
          minWidth: props.large ? LARGE_INPUT_MIN_WIDTH : SMALL_INPUT_MIN_WIDTH,
          background: WHITE,
          ...inputSx,
        }}
      />
    </LabelWrapper>
  );
};

export const ActivableToggle = ({
  componentIfFalse,
  componentIfTrue,
  elementName,
  label,
  sideMutation,
  wrapperStyle,
}: {
  componentIfFalse?: React.ReactNode;
  componentIfTrue?: React.ReactNode;
  elementName: string;
  label: string;
  sideMutation?:
    | ((newValue: boolean, mutateElement: AnyFunction) => void)
    | undefined;
  wrapperStyle?: CSSProperties;
}) => {
  return (
    <FormWatcher watchPath={elementName}>
      {({ watchedValue }) => {
        return (
          <VerticalLayout gap={sizing(2)} overflow="visible">
            <ToggleInputField
              elementName={elementName}
              label={label}
              noIndent
              wrapperStyle={{
                width: "100%",
                padding: padding(0),
                overflow: "visible",
                ...wrapperStyle,
              }}
              sideMutation={sideMutation}
            />
            {watchedValue ? componentIfTrue : componentIfFalse}
          </VerticalLayout>
        );
      }}
    </FormWatcher>
  );
};

export type Footnote = {
  description: (translations: Translations) => string;
  label: (translations: Translations) => string;
  superText: 1 | 2;
};

export const getFootnoteAriaLabel = (
  footNote: Footnote,
  translations: Translations,
) => `${footNote.label(translations)} - ${footNote.description(translations)}`;

export const FootnoteDescription = ({ footnote }: { footnote: Footnote }) => {
  const translations = useTranslations();

  return (
    <Caption aria-hidden margin={margin(0, 0, 1)}>
      <sup style={{ paddingRight: dp(2) }}>{footnote.superText}</sup>
      {footnote.description(translations)}
    </Caption>
  );
};

export const FootnoteLabel = ({ footnote }: { footnote: Footnote }) => {
  const translations = useTranslations();

  return (
    <>
      <span style={visuallyHidden}>
        {getFootnoteAriaLabel(footnote, translations)}
      </span>
      <span tabIndex={-1} aria-hidden style={{ position: "relative" }}>
        {footnote.label(translations)}
        <sup
          tabIndex={-1}
          aria-hidden
          style={{ paddingLeft: dp(2), position: "absolute", top: -8 }}
        >
          {footnote.superText}
        </sup>
      </span>
    </>
  );
};

export const YesNoRadioWithLabel = ({
  elementName,
  flatModel,
  formLabelSx,
  startWithNo,
  label,
  subForms = {},
  sideMutation,
}: {
  elementName: string;
  flatModel?: boolean;
  formLabelSx?: CSSProperties;
  label: string;
  sideMutation?:
    | ((
        newValue: any,
        mutateElement: (value: any, elementName: string) => void,
      ) => void)
    | undefined;
  startWithNo?: boolean;
  subForms?: { [key: string]: React.ReactNode };
}) => {
  const translations = useTranslations();

  const defaultYesNoOptions: RadioOptionV2[] = [
    {
      id: PREDICAMENT_STATE_YES,
      value: PREDICAMENT_STATE_YES,
      label: translations.actions.yes,
      subForm: subForms[PREDICAMENT_STATE_YES],
    },
    {
      id: PREDICAMENT_STATE_NO,
      value: PREDICAMENT_STATE_NO,
      label: translations.actions.no,
      subForm: subForms[PREDICAMENT_STATE_NO],
    },
  ];

  return (
    <RadioGroupV2
      label={label}
      formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
      formLabelSx={{
        padding: padding(1, 0),
        fontSize: FONT_SIZE_14,
        ...formLabelSx,
      }}
      elementName={elementName}
      flatModel={flatModel}
      options={
        startWithNo ? defaultYesNoOptions.reverse() : defaultYesNoOptions
      }
      radioSx={RADIO_WHITE_BACKGROUND}
      sideMutation={sideMutation}
    />
  );
};

export const CheckboxWithTextfield = ({
  checkboxElementName,
  descriptionElementName,
  label,
}: {
  checkboxElementName: string;
  descriptionElementName: string;
  label: string;
}) => {
  const translations = useTranslations();
  return (
    <>
      <CheckboxInputField
        elementName={checkboxElementName}
        flatModel
        label={label}
        sideMutation={(value, mutate) => {
          if (!value) {
            return mutate(null, descriptionElementName);
          }
        }}
      />
      <FormWatcher watchPath={checkboxElementName}>
        {({ watchedValue: complicationSelected }) => {
          if (!complicationSelected) return null;

          return (
            <TextAreaWithLabel
              elementName={descriptionElementName}
              flatModel
              label={
                translations.patientForms.medicalForm.sectionTwo
                  .complicationsDescriptionLabel
              }
              marginOverride={margin(0)}
            />
          );
        }}
      </FormWatcher>
    </>
  );
};

const formatDate = (timestamp: number, locale: Locale) =>
  format(fromUnixTime(timestamp), "P", { locale });

const formatDateTime = (timestamp: number, time: number, locale: Locale) =>
  `${formatDate(timestamp, locale)} ${format(fromUnixTime(time), "HH:mm", {
    locale,
  })}`;

export const DownloadSectionWrapper = ({
  body,
  lastUpdated,
  onClickPrint,
  onClickSendToHis,
  pdfName,
  progressOpenPdf,
  progressSendToHis,
  title,
}: {
  body: string;
  lastUpdated: number;
  onClickPrint: () => void;
  onClickSendToHis: () => void;
  pdfName: string;
  progressOpenPdf: QueryProgressActive;
  progressSendToHis: QueryProgressActive;
  title: string;
}) => {
  const translations = useTranslations();
  const { currentLocale: locale } = useDateLocale();
  const { hasTwoWaySync } = useHL7ImportStatusContext();

  return (
    <VerticalLayout
      data-testid="pdf-download-section"
      margin={margin(0, 0, 3)}
      width="100%"
      padding={padding(2, 3, 1)}
      style={{
        border: border({ width: 2, color: PRIMARY_DARK_COLOR }),
        borderRadius: sizing(1),
        boxSizing: "border-box",
      }}
    >
      <Title margin={margin(0, 0, 1.5)} as="h2">
        {title}
      </Title>
      <Body
        fontWeight={FONT_WEIGHT_REGULAR}
        margin="0"
        as="p"
        maxWidth="100%"
        fontSize={FONT_SIZE_14}
      >
        {body}
      </Body>
      <HorizontalLayout style={{ columnGap: sizing(3) }} aligned>
        <HorizontalLayout aligned>
          <Body
            margin="0"
            as="p"
            fontWeight={FONT_WEIGHT_BOLD}
            fontSize={FONT_SIZE_14}
          >
            {pdfName}
          </Body>
          <LockIcon
            size={16}
            style={{ margin: margin(0, 0, 0, 1), color: GREY_600 }}
          />
        </HorizontalLayout>
        <Body
          margin="0"
          as="p"
          color={GREY_600}
          fontWeight={FONT_WEIGHT_MEDIUM}
          fontSize={FONT_SIZE_14}
        >
          {translations.patientForms.transitionalCareForm.downloadSection.savedAt(
            {
              savedAt: formatDateTime(lastUpdated, lastUpdated, locale),
            },
          )}
        </Body>
        <RSButton
          color="primary"
          id="download"
          loading={progressOpenPdf}
          onClick={onClickPrint}
          style={{ width: "inherit" }}
          variant="text"
          LeftIcon={() => <PrinterIcon size={16} />}
        >
          {
            translations.patientForms.transitionalCareForm.downloadSection
              .printButton
          }
        </RSButton>
        {hasTwoWaySync ? (
          <RSButton
            id="send_to_his"
            loading={progressSendToHis}
            onClick={onClickSendToHis}
            variant="text"
            color="primary"
            LeftIcon={() => <FolderInputIcon size={FONT_SIZE_16} />}
          >
            {
              translations.patientForms.transitionalCareForm.downloadSection
                .hisButton
            }
          </RSButton>
        ) : null}
      </HorizontalLayout>
    </VerticalLayout>
  );
};

export const PatientFormsSectionHeader = ({
  children,
}: {
  children: string;
}) => {
  return (
    <Subheading as="h4" margin={margin(0)} style={{ fontSize: FONT_SIZE_24 }}>
      {children}
    </Subheading>
  );
};

export const PatientFormSubheading = ({
  children,
  style,
  withBottomPadding,
}: {
  children: string;
  style?: CSSProperties;
  withBottomPadding?: boolean;
}) => (
  <Subheading
    as="h5"
    style={{
      padding: padding(GAP, 0, withBottomPadding ? 1 : 0),
      fontSize: FONT_SIZE_20,
      fontWeight: FONT_WEIGHT_BOLD,
      margin: margin(0, 0, 0.5, 0.5),
      ...style,
    }}
  >
    {children}
  </Subheading>
);
