import React, { useEffect, useState } from "react";

export const RENDER_DELAY = 200;

function LoadingDelay({
  children,
  delay,
}: {
  children: (isDelaying: boolean) => React.ReactNode;
  delay: number;
}) {
  const [isDelaying, setIsDelaying] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsDelaying(false);
    }, delay);
    return () => clearTimeout(timeout);
  }, []);

  return <>{children(isDelaying)}</>;
}

export const RenderDelay = ({
  children,
  delay = RENDER_DELAY,
}: {
  children: React.ReactNode;
  delay?: number;
}) => (
  <LoadingDelay delay={delay}>
    {(isDelaying) => {
      if (isDelaying) return null;
      return children;
    }}
  </LoadingDelay>
);
