import {
  addDays,
  addMinutes,
  getUnixTime,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

export const getDate = (dayShift = 0, minuteShift = 0) =>
  getUnixTime(
    zonedTimeToUtc(
      addMinutes(
        addDays(
          setMilliseconds(
            setSeconds(setMinutes(setHours(new Date(), 8), 20), 3),
            0,
          ),
          dayShift,
        ),
        minuteShift,
      ),
      "UTC",
    ),
  );

export function getDelimiter(locale: string | null | undefined) {
  switch (locale) {
    case "en":
    case "fr":
      return "/";
    case "de":
    default:
      return ".";
  }
}
