const KEY_ENTER = "Enter";
const KEY_SPACE = "Space";

const confirmKeys = [KEY_ENTER, KEY_SPACE];

const KEY_ESCAPE = "Escape";
const KEY_DELETE = "Backspace";

const cancelKeys = [KEY_ESCAPE, KEY_DELETE];

export const handleKeyDown =
  ({
    onCancel,
    onConfirm,
  }: {
    onCancel?: (event: React.KeyboardEvent) => void;
    onConfirm?: (event: React.KeyboardEvent) => void;
  }) =>
  (event: React.KeyboardEvent): void => {
    if (confirmKeys.includes(event.code)) {
      event.preventDefault();
      onConfirm?.(event);
    }
    if (cancelKeys.includes(event.code)) onCancel?.(event);
  };
