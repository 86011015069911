import { isFutureDay, isSameOrBeforeToday } from "core/model/utils/dates";
import { TransitionalCareFormData } from "core/types";
import { fromUnixTime, isBefore, isToday, startOfDay } from "date-fns";
import { composeValidation } from "react-forms-state/src";
import Translations from "translations/types";

export const validatePatientConfirmation = (
  formInput: TransitionalCareFormData,
  props: { translations: Translations },
) => {
  if (formInput.transitional_care_patient_confirmation_explanation) return true;

  if (!formInput.transitional_care_patient_confirmation)
    return {
      transitional_care_patient_confirmation_explanation: {
        customMessage:
          props.translations.patientForms.transitionalCareForm
            .validationErrorMessage,
      },
    };

  return true;
};

export const validateTransitionalCareDate = ({
  customMessage,
  date,
  fieldName,
  validate,
}: {
  customMessage: string;
  date: number | undefined;
  fieldName: keyof TransitionalCareFormData;
  validate: (date: number) => boolean;
}) => {
  if (date) {
    return validate(date)
      ? true
      : {
          [fieldName]: {
            customMessage,
          },
        };
  }
  return true;
};

const validateHandoverToInsurance = (
  formInput: TransitionalCareFormData,
  props: { translations: Translations },
) =>
  validateTransitionalCareDate({
    fieldName: "handover_to_insurance_date",
    date: formInput.handover_to_insurance_date,
    validate: (date) => isToday(fromUnixTime(date)) || isFutureDay(date),
    customMessage:
      props.translations.patientForms.transitionalCareForm.errorDateInTheFuture,
  });

export const validateBirthdate = (
  formInput: TransitionalCareFormData,
  props: { translations: Translations },
) =>
  validateTransitionalCareDate({
    fieldName: "patient_date_of_birth",
    date: formInput.patient_date_of_birth,
    validate: (date) => isSameOrBeforeToday(date),
    customMessage:
      props.translations.patientForms.transitionalCareForm.errorDateInThePast,
  });

export const validatePriorHospitalTreatmentStartDate = (
  formInput: TransitionalCareFormData,
  props: { translations: Translations },
) =>
  validateTransitionalCareDate({
    fieldName: "prior_treatment_start_date",
    date: formInput.prior_treatment_start_date,
    validate: (date) => isSameOrBeforeToday(date),
    customMessage:
      props.translations.patientForms.transitionalCareForm.errorDateInThePast,
  });

export const validatePriorHospitalTreatmentEndDate = (
  formInput: TransitionalCareFormData,
  props: { translations: Translations },
) => {
  const { prior_treatment_end_date, prior_treatment_start_date } = formInput;

  if (
    prior_treatment_end_date &&
    prior_treatment_start_date &&
    isBefore(
      startOfDay(fromUnixTime(prior_treatment_end_date)),
      startOfDay(fromUnixTime(prior_treatment_start_date)),
    )
  ) {
    return {
      prior_treatment_end_date: {
        customMessage:
          props.translations.patientForms.transitionalCareForm
            .errorDischargeBeforeIntake,
      },
    };
  }

  return true;
};

export const validateTransitionalCareSearchStartDate = (
  formInput: TransitionalCareFormData,
  props: { translations: Translations },
) =>
  validateTransitionalCareDate({
    fieldName: "transitional_care_start_search_date",
    date: formInput.transitional_care_start_search_date,
    validate: (date) => isSameOrBeforeToday(date),
    customMessage:
      props.translations.patientForms.transitionalCareForm.errorDateInThePast,
  });

export const validateTransitionalCareEndDate = (
  formInput: TransitionalCareFormData,
  props: { translations: Translations },
) => {
  const { transitional_care_end_date, transitional_care_start_date } =
    formInput;

  if (
    transitional_care_end_date &&
    transitional_care_start_date &&
    isBefore(
      startOfDay(fromUnixTime(transitional_care_end_date)),
      startOfDay(fromUnixTime(transitional_care_start_date)),
    )
  ) {
    return {
      transitional_care_end_date: {
        customMessage:
          props.translations.patientForms.transitionalCareForm
            .errorDischargeBeforeIntake,
      },
    };
  }

  return true;
};

export const validatePatientConfirmationDate = (
  formInput: TransitionalCareFormData,
  props: { translations: Translations },
) =>
  validateTransitionalCareDate({
    fieldName: "transitional_care_patient_confirmation_date",
    date: formInput.transitional_care_patient_confirmation_date,
    validate: (date) => isSameOrBeforeToday(date),
    customMessage:
      props.translations.patientForms.transitionalCareForm.errorDateInThePast,
  });

export const validateAfterCareApplicationDate = (
  formInput: TransitionalCareFormData,
  props: { translations: Translations },
) =>
  validateTransitionalCareDate({
    fieldName: "aftercare_application_date",
    date: formInput.aftercare_application_date,
    validate: (date) => isSameOrBeforeToday(date),
    customMessage:
      props.translations.patientForms.transitionalCareForm.errorDateInThePast,
  });

export const validateCareLevelApplicationDate = (
  formInput: TransitionalCareFormData,
  props: { translations: Translations },
) =>
  validateTransitionalCareDate({
    fieldName: "aftercare_care_level_applied_date",
    date: formInput.aftercare_care_level_applied_date,
    validate: (date) => isSameOrBeforeToday(date),
    customMessage:
      props.translations.patientForms.transitionalCareForm.errorDateInThePast,
  });

export const validateInsuranceSentDate = (
  formInput: TransitionalCareFormData,
  props: { translations: Translations },
) =>
  validateTransitionalCareDate({
    fieldName: "insurance_form_sent_date",
    date: formInput.insurance_form_sent_date,
    validate: (date) => isSameOrBeforeToday(date),
    customMessage:
      props.translations.patientForms.transitionalCareForm.errorDateInThePast,
  });

export const validateTransitionalCareFormDates = composeValidation(
  validateHandoverToInsurance,
  validateBirthdate,
  validatePriorHospitalTreatmentStartDate,
  validatePriorHospitalTreatmentEndDate,
  validateTransitionalCareEndDate,
  validateTransitionalCareSearchStartDate,
  validatePatientConfirmationDate,
  validateAfterCareApplicationDate,
  validateCareLevelApplicationDate,
  validateInsuranceSentDate,
);
